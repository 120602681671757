/* eslint-disable */
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoginButton from 'components/Login';

import 'prismjs/themes/prism-okaidia.css';
import 'assets/css/variables';
import 'assets/css/global';

import ReactToPrint from 'react-to-print';
import Helmet from 'react-helmet';
import Article from '@react-website-themes/classy-docs/components/Article';
import Branding from '@react-website-themes/classy-docs/components/Branding';
import Bodytext from '@react-website-themes/classy-docs/components/Bodytext';
import Footer from '@react-website-themes/classy-docs/components/Footer';
import Header from '@react-website-themes/classy-docs/components/Header';
import Heading from '@react-website-themes/classy-docs/components/Heading';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import Menu from '@react-website-themes/classy-docs/components/Menu';
import Hero from '@react-website-themes/classy-docs/components/Hero';
import Sidebar from 'components/Sidebar';
import Logo from 'assets/images/logo.png';
import layoutSidebar from '@react-website-themes/classy-docs/styles/layoutSidebar';
import { isAuthenticated } from '../Auth/AuthService';
import LogoutButton from 'components/Logout';
import { css } from 'emotion';
import styled from 'react-emotion';

import config from 'meta/config';
import menuItems from 'meta/menu';
import categoryList from 'meta/categories';
import PrintIcon from 'react-feather/dist/icons/printer';

const ImageFullWidth = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`;

const HideCredits = css`
  .credits {
    display: none;
  }
`;

const StyledPrint = styled.a`
  @media only screen and (max-width: 1024px) {
    display:none;
  }
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  color: var(--lightTextColor);
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--firstActiveColor);
    width: 20px;
    height: 0px;
    transition: 0.3s;
    transform: translateY(8px);
  }

  &.active:before {
    height: 5px;
    transform: translateY(0);
  }

  &:not(.active) {
    @media (hover: hover) {
      &:hover:before {
        height: 5px;
        transform: translateY(.8em);
        background: var(--secondActiveColor);
      }
    }
  }

  svg {
    margin: 0 5px 0 0;
    height: 20px;
    width: 20px;
  }
`;

const HeroButton = styled.button`
  align-items: center;
  border-radius: 3px;
  color: var(--firstActiveColor);
  font-size: 1em;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1em 2em;
  background: #f9f9f9;
  margin: 40px 0 0;
`;

class PageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {
    this.setAuthentication();
  }

  setAuthentication() {
    this.setState({ authenticated: isAuthenticated() });
  }

  render() {
    const {
      location: {
        pathname,
      },
      data: {
        pages: {
          edges: nodePages,
        },
        page: {
          edges: pageEdges,
        },
        footerLinks: {
          edges: footerLinksEdges,
        },
        copyright: {
          edges: copyrightEdges,
        },
      },
    } = this.props;

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: footerLinksHTML,
          },
        },
      },
    } = footerLinksEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: copyrightHTML,
          },
        },
      },
    } = copyrightEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: pageHTML,
          },
        },
        title,
        slug,
      },
    } = pageEdges[0];

    const { authenticated } = this.state;

    const { headerTitle, headerSubTitle } = config;

    const pages = nodePages.map(item => item.node);
    const layoutStyle = layoutSidebar;

    localStorage.setItem('redirect', pathname);

    return (
      <React.Fragment>
        <Helmet title={title + " | Able Policies"} />
        {layoutStyle && (<Sidebar title="Table of contents" pages={pages} categoryList={categoryList} pathname={slug} />)}
        <Layout themeStyle={layoutStyle}>
          <Header>
            <Branding title={headerTitle} subTitle={headerSubTitle} />
            <Menu items={menuItems} />
            {
              authenticated && (
                <ReactToPrint
                  trigger={() => <StyledPrint href="#">
                    <PrintIcon />
                    <span>Print</span>
                  </StyledPrint>} content={() => this.componentRef}/>)
            }
            {
              authenticated && (
                <LogoutButton setAuthentication={this.setAuthentication} pathname={pathname} />
              )}
          </Header>
          {
            !authenticated && (
              <Hero>
                <LoginButton setAuthentication={this.setAuthentication} pathname={pathname} />
              </Hero>
            )
          }
          {
            authenticated && (
              <div ref={el => (this.componentRef = el)}>
                <Article>
                  <Heading title={title} />
                  <ImageFullWidth>
                    <Bodytext html={pageHTML} />
                  </ImageFullWidth>
                </Article>
              </div>
            )
          }
          <Footer customStyle={HideCredits} links={footerLinksHTML} copyright={copyrightHTML} />
        </Layout>
    </React.Fragment>);
  }
}

export default PageTemplate;

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    page: allContentfulPolicy(
      filter: {
        slug: { eq: $slug}
      })
    {
      edges {
        node {
          id
          title
          date
          category
          slug
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    pages: allContentfulPolicy {
      edges {
        node {
          id
          title
          date
          category
          slug
          headings
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    footerLinks: allContentfulParts(
      filter: {
            title: { eq: "footerLinks"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    copyright: allContentfulParts(
      filter: {
            title: { eq: "copyrightNote"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
