/* eslint-disable */
import React from 'react';
import { Link } from 'gatsby';
import { cx } from 'emotion';
import GithubSlugger from 'github-slugger';
import style from './SidebarStyles';

const slugs = new GithubSlugger();

function organizePagesInCategories(pages, categoryList) {
  const catArray = [];
  categoryList.forEach((cat) => {
    const tempArray = [];
    pages.forEach((page) => {
      if (page.category === cat.name) {
        tempArray.push(page);
      }
    });
    tempArray.sort(
      (a, b) => ((a.title > b.title)
        ? 1
        : (
          (b.title > a.title)
            ? -1
            : 0)),
    );
    if (tempArray.length > 0) {
      catArray.push(tempArray);
    }
  });
  return catArray;
}

function organizePagesWithHeadings(pages) {
  const headingsPages = [];

  pages.forEach((page) => {
    if (page.headings !== undefined || page.headings !== null) {
      headingsPages.push(page);
    }
  });
  return headingsPages;
}

const Sidebar = (props) => {
  const {
    themeStyle = style,
    customStyle = '',
    title,
    pages,
    categoryList,
    pathname,
  } = props;

  const pagesInCategories = organizePagesInCategories(pages, categoryList);

  return (
    <aside className={cx(themeStyle, customStyle)}>
      <h2>{title}</h2>
      {
      pagesInCategories.map((pages) => {
        let label;


        let Icon;

        categoryList.forEach((index) => {
          if (index.name === pages[0].category) {
            Icon = index.icon;
            label = index.label;
          }
        });

        return (
          <React.Fragment key={label}>
            <h3>
              <Icon />
              {' '}
              {label}
            </h3>
            <ul>
              {
              pages.map((page) => {
                const { title, slug, headings } = page;

                return (
                  <li
                    key={slug}
                    className={pathname === slug
                      ? 'active'
                      : ''}
                  >
                    <Link key={slug} to={slug}>
                      {title}
                    </Link>
                    <ul>

                      {
                      headings.map((heading) => {
                        slugs.reset();
                        if (heading === 'none') {
                          <li
                            key={heading}
                            style={{
                              display: 'none',
                            }}
                          >
                            <a href={`#${slugs.slug(heading)}`}>{heading}</a>
                          </li>;
                        } else {
                          return (
                            <li key={heading}>
                              <a href={`#${slugs.slug(heading)}`}>{heading}</a>
                            </li>
                          );
                        }
                      })
                    }
                    </ul>
                  </li>
                );
              })
            }
            </ul>
          </React.Fragment>
        );
      })
    }
    </aside>
  );
};

export default Sidebar;
